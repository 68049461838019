<template>
  <el-card class='remittances-draw-money business-handling my-card' :body-style="cardBody">
    <el-page-header @back="$router.go(-1)" :title="$t('Back')">
      <template slot="content"><span style="font-size:14px">{{$t('drawMoneyBusiness')}} / {{$t('remittancesDrawMoney')}}</span></template>
    </el-page-header>
    <div class="form-container">
      <el-divider content-position="left">{{$t('incomeRecord')}}</el-divider>
      <el-form inline ref="queryForm" :model="queryForm" :rules="rules" style="height:60px;overflow:hidden;">
        <el-form-item :label="$t('linkPhone')" prop="phone" style="margin-bottom:0">
          <el-input size="medium" v-model="queryForm.phone"></el-input>
        </el-form-item>
        <el-form-item style="margin-bottom:0">
          <el-button size="medium" type="primary" @click="getRemittanceList('queryForm')" :loading="isLoading">{{$t('query')}}{{$t('remittanceInfo')}}</el-button>
        </el-form-item>
      </el-form>
      <!-- 收到汇款信息的列表 -->
      <el-card v-if="remittanceList" class="box-card remittance-information-list" shadow="never" :body-style="{ padding: '0px 20px' }">
        <div slot="header" class="clearfix">
          <span>{{$t('remittanceInfo')}}</span>
        </div>
        <el-table size="small" ref="singleTable" :data="remittanceList" @row-click="selectionChange" highlight-current-row style="width: 100%;" max-height="300px" v-loading="isCompute" :element-loading-text="$t('Handling fee calculation')" @select="handleSelectionChange">
          <el-table-column type="selection" width="50"> </el-table-column>
          <el-table-column prop="customerName" :label="$t('Name of Remitter')">
            <template slot-scope="scope">{{scope.row.remitterName+" "+scope.row.remitterSurname}} </template>
          </el-table-column>
          <el-table-column prop="remitterPhone" :label="$t('linkPhone')"></el-table-column>
          <el-table-column prop="credentialNo" :label="$t('The remittance voucher')"></el-table-column>
          <el-table-column prop="amount" :label="$t('The remittance amount')">
            <template slot-scope="scope"> ₣ {{scope.row.amount.formatMoney(0)}} </template>
          </el-table-column>
          <el-table-column prop="transactionCode" :label="$t('Transaction no.')"></el-table-column>
          <el-table-column prop="payServiceChargeType" :label="$t('Service Fee Payment Method')">
            <template slot-scope="scope">
              <span v-if="scope.row.payServiceChargeType==0">{{$t('remitterPayment')}}</span>
              <span v-if="scope.row.payServiceChargeType==1">{{$t('payeePayment')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="customerName" :label="$t('payeeName')"></el-table-column>
          <el-table-column prop="phone" :label="$t('payeePhone')"></el-table-column>
        </el-table>
      </el-card>
      <el-divider content-position="left" class="update-el-divider">{{$t('withdrawalInfo')}}</el-divider>
      <el-form v-if="withdrawalInfo" ref="withdrawalInfo" :model="withdrawalInfo" :rules="rules2" label-position="top">
        <el-input type="hidden" size="medium" v-model="withdrawalInfo.businessCode" disabled></el-input>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item :label="$t('withdrawalAmount')" prop="amount">
              <el-input size="medium" v-model="withdrawalInfo.amount" disabled :placeholder="$t('Please select the remittance record')">
                <template slot="prepend">₣</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item :label="$t('Service Fee Payment Method')" prop="payServiceChargeType">
              <el-select size="medium" v-model="withdrawalInfo.payServiceChargeType" disabled :placeholder="$t('Please select the remittance record')">
                <el-option :label="$t('remitterPayment')" :value="0"></el-option>
                <el-option :label="$t('payeePayment')" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="withdrawalInfo.payServiceChargeType==1">
            <el-form-item :label="$t('serviceCharge')" prop="serviceChargeAmount">
              <el-input size="medium" v-model="withdrawalInfo.serviceChargeAmount" disabled>
                <template slot="prepend">₣</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <transaction-tax-display businessCode="51" :transaction-amount="withdrawalInfo.amount"></transaction-tax-display>
          </el-col>
          <el-col :span="4">
            <el-form-item :label="$t('idNumber')" prop="idCard2" style="margin-bottom:0">
              <el-input size="medium" v-model="withdrawalInfo.idCard2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="withdrawalInfo.businessCode!='22'">
            <el-form-item :label="$t('messageAuthenticationCode')" prop="withdarwMoneyCode">
              <el-input size="medium" v-model="withdrawalInfo.withdarwMoneyCode"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('remark')">
              <el-input size="medium" type="textarea" v-model="withdrawalInfo.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="bottom-button" v-if="withdrawalInfo">
      <el-button size="medium" :loading="isLoading" type="primary" @click="onSubmit('withdrawalInfo')">{{$t('submit')}}</el-button>
    </div>

    <el-dialog :title="$t('printrRceipt')" :visible.sync="printVisible" width="500px" :modal-append-to-body="false" :destroy-on-close="true" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <print-credentials :param="printParam" :isClosePrint.sync="printVisible" />
    </el-dialog>

  </el-card>
</template>

<script>
import printCredentials from '@/components/printCredentials'
import TransactionTaxDisplay from '@/components/TransactionTaxDisplay'
import transactionInfo from '@/api/transactionInfo'
export default {
  components: { printCredentials, TransactionTaxDisplay },
  data () {
    return {
      /**查询汇款记录Form */
      queryForm: {},
      remittanceList: [],
      isLoading: false,

      /**取款信息 */
      withdrawalInfo: {},

      isCompute: false,

      //回执单
      printVisible: false,
      printParam: null,
    }
  },
  computed: {
    cascader () { return this.$store.state.format.cascader },
    cardBody () { return this.$store.state.style.cardBody },
    countryTree () { return this.$store.state.globalParameter.countryTree; },
    rules () {
      return {
        idCard: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        phone: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        amount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        serviceChargeAmount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payServiceChargeType: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        withdarwMoneyCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    },
    rules2 () {
      return {
        idCard2: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        phone: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        amount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        serviceChargeAmount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payServiceChargeType: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        withdarwMoneyCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    }
  },
  methods: {
    getRemittanceList (refName) {
      let _this = this;
      _this.$refs[refName].validate(valid => {
        if (!valid) return false;
        _this.isLoading = true;
        transactionInfo.getByPhoneAndIdCard({
          param: _this.queryForm,
          success: res => {
            if (res.code != 200) {
              _this.remittanceList = null;
              _this.$message.warning(_this.$t(res.msg));
            }
            else {
              let list = JSON.parse(JSON.stringify(res.data))
              _this.remittanceList = list.filter(function (l) {
                l.withdarwMoneyCode = undefined;
                return l.amount > 0
              });
            }
            _this.isLoading = false;
          }
        })
      })
    },
    /**点击复选框时触发 */
    handleSelectionChange (rows) { setTimeout(() => { this.selectionChange(rows[1] || rows[0]) }, 50); },
    /**点击行触发 */
    selectionChange (row) {
      if (!row) {
        this.$set(this, 'withdrawalInfo', null);
        return;
      }
      // console.log("-----selectionChange----------", row.credentialNo)
      this.$refs.singleTable.clearSelection();
      this.$refs.singleTable.toggleRowSelection(row);
      let item = JSON.parse(JSON.stringify(row))
      this.$set(this, 'withdrawalInfo', item);
    },
    /**提交取款信息 */
    onSubmit (refName) {
      let _this = this;
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        _this.isLoading = true;
        let param = {
          businessCode: _this.$route.params.code,
          idCard: _this[refName].idCard2,
          phone: _this.queryForm.phone,
          transactionInfoId: _this[refName].transactionInfoId,
          withdarwMoneyCode: _this[refName].withdarwMoneyCode,
          remark: _this[refName].remark,
        }
        transactionInfo.remittanceWithdrawals({
          param: param,
          success: res => {
            if (res.code == 200) {
              _this.$message.success(_this.$t('operateSuccessfully'));

              _this.$set(_this, "printParam", res.data)
              _this.$set(_this, "printVisible", true)
            }
            else {
              _this.$message.warning(_this.$t(res.msg));
            }
            _this.isLoading = false;
          }
        })
      })
    },

  }
}
</script>

<style lang="less" scoped>
.remittances-draw-money.business-handling {
  height: 100%;
  width: 100%;
  .el-card__body {
    position: relative;
    .form-container {
      position: absolute;
      top: 60px;
      bottom: 80px;
      left: 20px;
      right: 0;
      padding-right: 20px;
      overflow: auto;
    }
  }
  .el-form {
    height: auto;
  }
  .update-el-divider {
    margin-top: 40px;
  }
}
</style>

<style lang="less">
.remittances-draw-money {
  .box-card {
    margin-top: 20px;
    .clearfix {
      position: relative;
      button {
        position: absolute;
        top: -10px;
        left: 50%;
        color: @primary;
      }
    }
    .el-collapse-item__header {
      display: none;
    }
  }
  .el-table__header-wrapper tr .el-checkbox {
    display: none;
  }
}
</style>